import { isEqual, remove } from "lodash";

import {
  parseObjectToUrlParams,
  parseUrlParamsToObject,
} from "./url-helper.js";

export default {
  install(Vue) {
    Vue.URL_KEYS = [];
    Vue.mixin({
      data() {
        return {
          urlPersistenceHandledPopStateEvent: false,
        };
      },

      created() {
        if (this.urlPersistence) {
          this._initialUrlPersistenceValues = {};
          const urlObject =
            parseUrlParamsToObject(window.location.search) || {};

          this.urlPersistence.params.forEach((item) => {
            this._initialUrlPersistenceValues[item.prop] = this[item.prop];

            // Not moving this validation into its own vue method as to not introduce a new method
            // for validation on each vue component instance.
            // Keeping this here also gives access to `Vue.URL_KEYS` without passing it around
            // everywhere
            if (Vue.URL_KEYS.indexOf(item.prop) > -1) {
              throw `'prop' of ${item.prop} is already in use. 'prop' must be a unique key, otherwise you can provide a unique key by using the 'as' property.`;
            } else if (Vue.URL_KEYS.indexOf(item.as) > -1) {
              throw `'as' of ${item.as} is already in use. 'as' must be a unique key.`;
            } else {
              if (item.as) {
                Vue.URL_KEYS.push(item.as);
              } else {
                Vue.URL_KEYS.push(item.prop);
              }
            }

            if (urlObject && urlObject[item.as]) {
              this[item.prop] = urlObject[item.as];
            } else if (urlObject && urlObject[item.prop]) {
              this[item.prop] = urlObject[item.prop];
            }

            this.$watch(
              item.prop,
              function (val) {
                if (this.$data.urlPersistenceHandledPopStateEvent) {
                  this.$data.urlPersistenceHandledPopStateEvent = false;

                  return;
                }

                const currentUrlObject =
                  parseUrlParamsToObject(window.location.search) || {};

                const newUrlObject = { ...currentUrlObject };

                if (item.as) {
                  newUrlObject[item.as] = val;
                } else {
                  newUrlObject[item.prop] = val;
                }

                if (!isEqual(currentUrlObject, newUrlObject)) {
                  const urlParams = parseObjectToUrlParams(newUrlObject);
                  const url = new URL(window.location);

                  url.search = urlParams;

                  window.history.pushState({}, "", url.href);
                }
              },
              { deep: true },
            );
          });

          window.addEventListener(
            "popstate",
            this.handleUrlPersistenceParamsChange,
          );
        }
      },

      destroyed() {
        if (this.urlPersistence) {
          this.urlPersistence.params.forEach((item) => {
            remove(Vue.URL_KEYS, (el) => {
              if (item.as) {
                return el == item.as;
              } else {
                return el == item.prop;
              }
            });
          });

          window.removeEventListener(
            "popstate",
            this.handleUrlPersistenceParamsChange,
          );
        }
      },

      methods: {
        handleUrlPersistenceParamsChange() {
          const urlObject =
            parseUrlParamsToObject(window.location.search) || {};

          let propChanged = false;

          this.urlPersistence.params.forEach((item) => {
            let itemValue = urlObject[item.as] || urlObject[item.prop];

            if (itemValue === undefined) {
              itemValue = this._initialUrlPersistenceValues[item.prop];
            }

            if (itemValue !== this[item.prop]) {
              this[item.prop] = itemValue;
              propChanged = true;
            }
          });

          if (propChanged) {
            this.$data.urlPersistenceHandledPopStateEvent = true;
          }
        },
      },
    });
  },
};
