import { formatPageData } from "@/shared/pagination_helper.js";

import {
  parseUrlParamsToObject,
  parseObjectToUrlParams,
  parsePredicateToUrlParams,
} from "@/shared/url_parser.js";

import {
  formatDirectionEnum,
  unformatDirectionEnum,
  formatSortField,
} from "@/shared/sorter_helper.js";

import { get, isUndefined, map, isEqual, pick, merge } from "lodash";

export const DEFAULT_SORTED_INFO = { columnKey: "name", order: "ascend" };

export const formatTableData = (
  state,
  { pagination, filters, sorter, searchTerm, filterId },
) => {
  const data = {};

  if (!isUndefined(pagination)) {
    data["pagination"] = formatPaginationData(state, pagination);
  }

  if (!isUndefined(sorter)) {
    data["sort"] = formatSortData(sorter);
  }

  if (!isUndefined(filters)) {
    const connective = get(filters, "connective");

    data["filters"] = filters;
    data["connective"] = connective;
  }

  if (!isUndefined(filterId)) {
    data["filterId"] = filterId;
  }

  if (!isUndefined(searchTerm)) {
    data["searchTerm"] = searchTerm;
  }

  return data;
};

export const formatQueryParams = (state) => {
  const { currentPage, itemsPerPage } = state.pagination || {};

  return {
    sortBy: formatSortField(state.sort),
    filter: formatFilters(state.filters),
    filterId: state.filterId,
    connective: state.connective,
    search: state.searchTerm,
    ...formatPageData(currentPage, itemsPerPage),
  };
};

export const tableState = ({ itemsPerPage = 50 } = {}) => ({
  sort: {
    key: undefined,
    direction: undefined,
  },
  pagination: {
    currentPage: 1,
    itemsPerPage,
    updatedCount: undefined,
  },
  filters: {},
  searchTerm: "",
});

export const queryParamsFromTableState = (state) => {
  return {
    pagination: state.pagination,
    searchTerm: state.searchTerm,
    sorter: {
      columnKey: state.sort.key,
      order: state.sort.direction,
    },
    filters: state.filters,
  };
};

export const currentTableStateFromUrl = (
  data,
  {
    defaultSort = DEFAULT_SORTED_INFO,
    namespace,
    urlParamsIncludeList = [],
  } = {},
) => {
  let params = parseUrlParamsToObject(window.location.search);

  if (namespace) {
    params = params[namespace] || {};
  }

  const currentState = {
    searchTerm: "",
    filters: {},
    sorter: defaultSort,
    pagination: { current: 1 },
  };

  if (params.page) {
    Object.assign(currentState, {
      pagination: {
        current: parseInt(params.page),
      },
    });
  }

  if (params.sort) {
    Object.assign(currentState, {
      sorter: {
        columnKey: params.sort["by"],
        order: unformatDirectionEnum(params.sort["direction"]),
      },
    });
  }

  // This is for repos
  if (params.filters) {
    const predicates = get(params, "filters.predicates");

    if (predicates) {
      const filters = predicates.key.map((item, index) => {
        return {
          key: predicates.key[index],
          arg: predicates.arg[index],
          type: predicates.type[index],
        };
      });

      Object.assign(currentState, {
        filters: filters,
      });
    } else {
      Object.assign(currentState, {
        filters: params.filters,
      });
    }
  }

  // This is for services
  if (params.filter) {
    const filter = get(params, "filter");

    const filters = [];

    filter.forEach((predicate) => {
      if (predicate["predicates"]) {
        // We believe this logic is no longer needed and was added for potential multi-layered filters
        const inner_predicates = predicate["predicates"].map(
          ({ key, type, arg }) => {
            if (key.startsWith("tags.")) {
              return {
                key: "tag",
                type: type,
                arg: `${key.replace(/^tags./, "")}:${arg}`,
              };
            }

            if (key.startsWith("properties.")) {
              return {
                key: "property",
                type: type,
                arg: `${key.replace(/^properties./, "")}:${arg}`,
              };
            }

            return { key, type, arg };
          },
        );

        filters.push({
          predicates: inner_predicates,
          connective: predicate["connective"],
        });
      } else {
        if (predicate["key"].startsWith("tags.")) {
          // Infra-specific logic
          filters.push({
            key: "tag",
            type: predicate["type"],
            arg: `${predicate["key"].replace(/^tags./, "")}:${
              predicate["arg"]
            }`,
          });
        } else if (predicate["key"].startsWith("properties.")) {
          filters.push({
            key: "property",
            type: predicate["type"],
            arg: `${predicate["key"].replace(/^properties./, "")}:${
              predicate["arg"]
            }`,
          });
        } else {
          // Normal logic
          filters.push({
            key: predicate["key"],
            caseSensitive:
              !predicate["caseSensitive"] ||
              predicate["caseSensitive"] === "false"
                ? false
                : true,
            type: predicate["type"],
            arg: predicate["arg"],
          });
        }
      }
    });

    Object.assign(currentState, {
      filters: {
        predicates: filters,
        connective: params.connective,
      },
    });
  }

  if (params.filterId) {
    Object.assign(currentState, {
      filterId: params.filterId,
    });
  }

  if (params.findTerm && params.findTerm.length) {
    Object.assign(currentState, {
      searchTerm: params.findTerm,
      sorter:
        currentState.sorter && !isEqual(currentState.sorter, defaultSort)
          ? currentState.sorter
          : {},
    });
  }

  urlParamsIncludeList.forEach((field) => {
    if (!params[field]) {
      return;
    }

    currentState[field] = params[field];
  });

  return currentState;
};

export const saveTableStateToUrl = (
  data,
  {
    defaultSort = DEFAULT_SORTED_INFO,
    namespace,
    urlPersistenceVersion,
    urlParamsExcludeList = [],
    urlParamsIncludeList = [],
  } = {},
) => {
  let currentState = {};

  // pagination
  if (data.pagination && data.pagination.current !== 1) {
    Object.assign(currentState, {
      page: data.pagination.current,
    });
  }

  // sorting
  if (data.sorter && data.sorter.columnKey && data.sorter.order) {
    const sorterInfo = pick(data.sorter, Object.keys(defaultSort));

    if (isEqual(sorterInfo, defaultSort)) {
      currentState.sort = {};
    } else {
      Object.assign(currentState, {
        sort: {
          by: data.sorter.columnKey,
          direction: formatDirectionEnum(data.sorter.order),
        },
      });
    }
  }

  if (urlPersistenceVersion === 0) {
    // Don't save query params to the url
    return currentState;
  } else if (urlPersistenceVersion === 1) {
    const filterParams = parsePredicateToUrlParams(data);
    const urlParams = parseObjectToUrlParams(currentState);

    let fullParams = "";

    if (urlParams && filterParams) {
      fullParams = `${urlParams}&${filterParams}`;
    } else if (urlParams) {
      fullParams = urlParams;
    } else if (filterParams) {
      fullParams = `?${filterParams}`;
    }

    if (window.location.search !== fullParams) {
      window.history.pushState(
        currentState,
        "",
        fullParams || window.location.pathname,
      );
    }
  } else {
    const predicates = get(data, "filters.predicates");

    if (predicates) {
      Object.assign(currentState, {
        filter: predicates,
      });
    } else if (data.filters && Object.keys(data.filters).length) {
      Object.assign(currentState, {
        filters: data.filters,
      });
    }

    const connective = get(data, "filters.connective");

    if (connective) {
      Object.assign(currentState, {
        connective: connective,
      });
    }

    const filterId = get(data, "filterId");

    if (filterId) {
      Object.assign(currentState, {
        filterId: filterId,
      });
    }

    if (
      !excludeSearch(urlParamsExcludeList) &&
      data.searchTerm &&
      data.searchTerm.length
    ) {
      Object.assign(currentState, {
        findTerm: data.searchTerm,
      });
    }

    if (urlParamsIncludeList) {
      Object.assign(currentState, pick(data, urlParamsIncludeList));
    }

    if (namespace) {
      const previousState = parseUrlParamsToObject(window.location.search);

      if (!currentState.page) {
        delete previousState[namespace];
      }

      currentState = merge(previousState, { [namespace]: currentState });
    }

    // must compare state as objects to avoid ordering issues
    const stateFromUrl = parseUrlParamsToObject(window.location.search);
    const normalizedCurrentState = parseUrlParamsToObject(
      parseObjectToUrlParams(currentState),
    );

    if (!isEqual(stateFromUrl, normalizedCurrentState)) {
      const url = Object.assign(new URL(window.location), {
        search: parseObjectToUrlParams(currentState),
      });

      window.history.pushState(currentState, "", url);
    }

    return currentState;
  }
};

const excludeSearch = (excludeList) => {
  return excludeList.includes("searchTerm");
};

export const formatPaginationData = (state, pagination) => {
  const itemsPerPage =
    get(pagination, "pageSize") || state.pagination.itemsPerPage;
  const currentPage = computeCurrentPage(state, pagination, itemsPerPage);

  return { currentPage, itemsPerPage };
};

const computeCurrentPage = (state, pagination, itemsPerPage) => {
  if (!state.pagination) {
    return get(pagination, "current");
  }

  const currentPage =
    get(pagination, "current") || state.pagination.currentPage;
  const updatedCount = state.pagination.updatedCount;

  if (isUndefined(updatedCount)) {
    return currentPage;
  } else if (updatedCount <= 0) {
    return 1;
  }

  const pagesNeeded = Math.ceil(updatedCount / itemsPerPage);

  return Math.min(currentPage, pagesNeeded);
};

export const formatSortData = (sorter) => {
  const key = get(sorter, "columnKey");
  const direction = get(sorter, "order");

  return { key, direction };
};

export const formatFilters = (filters) => {
  if (typeof filters == "object" && filters.predicates) {
    return map(filters.predicates, (predicate) => {
      if (predicate.key == "tags") {
        return {
          key: "tag",
          type: predicate.type,
          arg: `${predicate.secondary_key}:${predicate.arg}`,
          caseSensitive: predicate.caseSensitive,
        };
      } else if (predicate.key == "properties") {
        return {
          key: "property",
          type: predicate.type,
          arg: `${predicate.secondary_key}:${predicate.arg}`,
          caseSensitive: true,
        };
      } else if (predicate.key) {
        const pred = {
          key: predicate.key,
          type: predicate.type,
          caseSensitive: predicate.caseSensitive,
        };
        const predicateArg = predicate.arg;

        if (predicateArg) {
          const arg = isNaN(predicateArg)
            ? predicateArg
            : predicateArg.toString();

          pred.arg = arg;
        }

        return pred;
      }

      return predicate;
    });
  }

  return map(filters, (filter, column) => {
    return {
      key: column,
      arg: filter,
      type: "equals",
    };
  });
};

export const prepareTablePersistence = (options, version = 2) => {
  return {
    defaultSort: DEFAULT_SORTED_INFO,
    ...options,
    urlPersistenceVersion: version,
  };
};

export const sortStateValidator = (sortState) => {
  return (
    typeof sortState === "object" &&
    (sortState.direction === undefined ||
      (typeof sortState.key === "string" &&
        typeof sortState.direction === "string"))
  );
};

export const paginationStateValidator = (paginationState) => {
  return (
    typeof paginationState === "object" &&
    typeof paginationState.currentPage === "number" &&
    typeof paginationState.itemsPerPage === "number"
  );
};
